import React from "react"
import { Badge } from "reactstrap"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/ListLayout"
import SEO from "../components/seo"
import Title from "../components/Title"
const { slugify_chinese } = require("../util/utilityFunctions")

const StyledWrapper = styled.div`
  &.tags-page {
    .tags-page__nav {
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;
      list-style: none;

      .tags-page__item {
        margin-right: 10px;
        margin-top: 10px;

        &__tag {
          border-width: 1px;
          border-style: solid;
          border-color: ${props => props.theme.tagColor};
          border-radius: 4px;
          padding: 3px 5px;
          color: ${props => props.theme.tagColor};

          > a {
            color: ${props => props.theme.tagColor};
            &:hover {
              color: ${props => props.theme.tagColor};
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`

const TagsPage = props => {
  const { tags, tagsCount } = props.pageContext

  return (
    <Layout>
      <SEO title="標籤" pathname={props.location.pathname} />
      <StyledWrapper className="tags-page">
        <Title>{`Tags(${tags.length})`}</Title>
        <ul className="tags-page__nav">
          {tags.map(tag => {
            return (
              <li className="tags-page__item" key={tag}>
                <div className="tags-page__item__tag">
                  <Link to={`/tag/${slugify_chinese(tag)}`}>
                    {tag} <Badge color="light">{tagsCount[tag]}</Badge>
                  </Link>
                </div>
              </li>
            )
          })}
        </ul>
      </StyledWrapper>
    </Layout>
  )
}

export default TagsPage
